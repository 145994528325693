import React from "react"
import Slider from "react-slick"

// import Lightbox from "../components/Lightbox/Lightbox"

const HomeSlider = ({ lang }) => {
  const homeSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    fade: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          dots: false,
          arrows: false,
        },
      },
    ],
  }

  return (
    <>
      <Slider {...homeSlider}>
        <div>
          <div
            className="home-top__slide"
            style={{
              backgroundImage: `url(${require("../assets/images/home-slider-image-1.jpg")})`,
            }}
          ></div>
        </div>
        <div>
          <div
            className="home-top__slide"
            style={{
              backgroundImage: `url(${require("../assets/images/home-slider-image-3.jpg")})`,
            }}
          ></div>
        </div>
        <div>
          <div
            className="home-top__slide"
            style={{
              backgroundImage: `url(${require("../assets/images/home-slider-image-4.jpg")})`,
            }}
          ></div>
        </div>
        <div>
          <div
            className="home-top__slide"
            style={{
              backgroundImage: `url(${require("../assets/images/home-slider-image-5.jpg")})`,
            }}
          ></div>
        </div>
        <div>
          <div
            className="home-top__slide"
            style={{
              backgroundImage: `url(${require("../assets/images/home-slider-image-6.jpg")})`,
            }}
          ></div>
        </div>
      </Slider>
    </>
  )
}

export default HomeSlider
